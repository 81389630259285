var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Voucher Type")]),_c('v-select',{attrs:{"placeholder":"None","options":['Sales Cancel', 'Bulk Land Sales Cancel', 'Other']},model:{value:(_vm.vouchertype),callback:function ($$v) {_vm.vouchertype=$$v},expression:"vouchertype"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Receiver Name")]),_c('v-select',{attrs:{"placeholder":"None","label":"fullname","options":_vm.$store.state.app.all_users},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var fullname = ref.fullname;
var profile_image = ref.profile_image;
var username = ref.username;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(fullname))]),_c('span',[_vm._v("("+_vm._s(username)+")")])]}}]),model:{value:(_vm.receiver),callback:function ($$v) {_vm.receiver=$$v},expression:"receiver"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Ledger Name")]),_c('v-select',{attrs:{"placeholder":"None","label":"name","options":_vm.ledgerOptions},model:{value:(_vm.ledger),callback:function ($$v) {_vm.ledger=$$v},expression:"ledger"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Select Date")]),_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"value":"","placeholder":"Select Date","config":{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                              }},on:{"input":function($event){return _vm.getdata($event)}}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary"},on:{"click":_vm.searchData}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SearchIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Search")])],1)],1)],1)])],1),_c('report-table',{attrs:{"data":_vm.paymentData,"columns":_vm.fields},scopedSlots:_vm._u([{key:"printHeader",fn:function(){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","width":"100%"}},[_c('h3',{staticStyle:{"margin":"0px"}},[_vm._v("Payment Report")]),_c('h4',{staticStyle:{"margin":"0px"}},[_vm._v(_vm._s(_vm.type))])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%","flex-direction":"column","align-items":"center"}},[(_vm.result && _vm.result.length==2)?_c('h5',{staticStyle:{"margin":"0px"}},[_vm._v(_vm._s(_vm.result[0])+" to "+_vm._s(_vm.result[1]))]):_vm._e()])]},proxy:true}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }