<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code >
                  <validation-observer ref="simpleRules">
                    <form @submit.prevent="submitForm">
                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label>Voucher Type</label>
                            <v-select
                            v-model="vouchertype"
                            placeholder="None"
                            :options="['Sales Cancel', 'Bulk Land Sales Cancel', 'Other']"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Receiver Name</label>
                            <v-select
                            v-model="receiver"
                            placeholder="None"
                            label="fullname"
                            :options="$store.state.app.all_users"
                            >
                            <template #option="{ fullname, profile_image, username }">
                            <b-avatar :src="getprofileImage(profile_image)" />
                            <span class="font-weight-bolder"> {{ fullname}}</span><span>({{ username }})</span>
                          </template></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Ledger Name</label>
                            <v-select
                            v-model="ledger"
                            placeholder="None"
                            label="name"
                            :options="ledgerOptions"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Select Date</label>
                              <flat-pickr
                                value=""
                                class="form-control"
                                placeholder="Select Date"
                                :config="{
                                dateFormat: 'd/m/Y',
                                mode: 'range',
                                }"
                                @input="getdata($event)"
                                style="background-color: transparent"/>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div class="d-flex justify-content-end">
                        <b-button  variant="primary" class="ml-2" @click="searchData">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          <span class="align-middle">Search</span>
                        </b-button>
                      </div>
                    </form>
                  </validation-observer>
              </b-card-code>
              <report-table
                :data="paymentData"
                :columns="fields"
              >
              <template #printHeader>
                    <div style="display:flex;flex-direction: column;align-items:center;width:100%;">
                      <h3 style="margin:0px">Payment Report</h3>
                      <h4 style="margin:0px">{{ type }}</h4>
                    </div>
                    <div style="display:flex;justify-content:center;width:100%;flex-direction:column;align-items:center">
                      <h5 style="margin:0px" v-if="result && result.length==2">{{result[0]}} to {{result[1]}}</h5>
                    </div>
                  </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from '../../../components/ReportTable.vue'
import axios from '@/components/axios'
import Table from '@/components/Table.vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Form from '@/components/form/Form.vue'
import moment from 'moment'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    vSelect,
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
    BAvatar
  },
  data () {
    return {
      receiver:"",
      ledger:"",
      ledgerOptions:[],
      paymentData: '',
      startdate: '',
      enddate: '',
      result: '',
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken:localStorage.getItem('accessToken'),
      vouchertype:'',
      fields: [
        // { field: 'id', label: 'S.R. No', hidden: true,},
        { field: 'vouchertype', label: 'Voucher Type',},
        { field: 'paymentdate', label: 'Payment date',},
        { field: 'receiver', label: 'Receiver Name'},
        { field: 'ledger', label: 'Ledger Name'},
        // { field: 'bankcash', label: 'Bank/Cash',},
        // { field: 'bankname', label: 'Bank Name',},
        // { field: 'branchname', label: 'Branch Name',},
        { field: 'chequeno', label: 'Cheque No',},
        // { field: 'chequedate', label: 'Cheque Date',},
        { field: 'remarks', label: 'Remarks',},
        { field: 'totalamount', label: 'Total Amount',}
      ],
      data1:[],
      supplierOptions:[]
    }
  },
  mounted () {
    this.getLedgers();

  },
  methods: {
    async getLedgers(){
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getLedger`,
      }).then((response) => {
        this.ledgerOptions = response.data.data;
      });
    },
    getdata (selectedDates) {
      this.result = selectedDates.split(' to ')
      this.startdate = this.result[0]
      this.enddate = this.result[1]
    },
    async searchData (tableData) {
      const obj = {
        vouchertype: this.vouchertype,
        receiver: this.receiver?this.receiver.id:'',
        ledger: this.ledger?this.ledger.id:'',
        startdate : this.startdate,
        enddate : this.enddate
      }
      await axios({
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data:JSON.stringify(obj),
        url:`${this.baseApi}/paymentreport`
      })
        .then((response) => {
          this.paymentData = response.data.data
          this.paymentData.map((item)=>{
            item.receiver =item.supplier?item.supplier.fullname:''
            item.ledger =item.ledger?item.ledger.name:''
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
